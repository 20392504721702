import { Controller } from 'stimulus'
import Globe from 'globe.gl'
import * as THREE from 'three'
import EarthImage from '../images/earth-day.jpg'
import WaterImage from '../images/earth-water.png'
import BumpImage from '../images/earth-topology.png'
import HexagonImage from '../images/hexagon.svg'
export default class extends Controller {
  static targets = ['startdate', 'enddate']
  initialize () {
    //   console.log("init");
    // const N = 20
    // const arcsData2 = [...Array(N).keys()].map(() => ({
    //   endLat: 52.407,
    //   endLng: 4.6585,
    //   startLat: (Math.random() - 0.5) * 180,
    //   startLng: (Math.random() - 0.5) * 360,
    //   color: [
    //     ['#ff0079', '#00cd68', '#312db4'][Math.round(Math.random() * 2)],
    //     ['#ff0079', '#00cd68', '#312db4'][Math.round(Math.random() * 2)],
    //     '#FFFFFF'
    //   ]
    // }))

    var clientHeight = document.getElementById('globeViz').clientHeight || 400
    var clientWidth = document.getElementById('globeViz').clientWidth || 400
    console.log(clientWidth, clientHeight)
    console.log('----')
    let arcdataelt = document.getElementById("arcdata");
    let arcsData = JSON.parse(arcdataelt.innerHTML);
    console.log(arcsData)
    let world = Globe()
      .globeImageUrl(EarthImage)
      .backgroundImageUrl(HexagonImage)
      .bumpImageUrl(BumpImage)
      .arcsData(arcsData)
      .arcColor('color')
      .backgroundColor('#404040')
      .width(clientWidth)
      .height(clientHeight)
      .atmosphereColor('#202020')
      .arcDashLength(0.8)
      .arcStroke(0.4)
      .arcDashGap(0.2)
      .showAtmosphere(true)
      .atmosphereAltitude(1.15)
      .arcDashAnimateTime(4500)(document.getElementById('globeViz'))
    const globeMaterial = world.globeMaterial()
    globeMaterial.bumpScale = 20
    globeMaterial.shininess = 35
    //globeMaterial.emissive = new THREE.Color('#105010')
    new THREE.TextureLoader().load(WaterImage, texture => {
      globeMaterial.specularMap = texture
      // globeMaterial.emissiveMap = texture
      // globeMaterial.emissiveIntensity = -0.3
      //globeMaterial.emissive = new THREE.Color('white')
      globeMaterial.specular = new THREE.Color('grey')
      globeMaterial.shininess = 5
    })

    setTimeout(() => {
      // wait for scene to be populated (asynchronously)
      const directionalLight = world
        .scene()
        .children.find(obj3d => obj3d.type === 'DirectionalLight')
      directionalLight && directionalLight.position.set(1, 1, 1) // change light position to see the specularMap's effect
    })
  }

  connect () {
    //   console.log("connect");
  }

  disconnect () {
    //   console.log("disc");
  }

  change_value () {
    const startDe = this.startdateTarget
    const endDe = this.enddateTarget
    const startdate = startDe.value
    const enddate = endDe.value

    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set("startdate", startdate);
    searchParams.set("enddate", enddate);
    window.location.search = searchParams.toString();

    // const url =
    //   window.location.href + '&startdate=' + startdate + '&enddate=' + enddate
    window.location.href = url
  }
}
