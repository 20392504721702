/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascripts and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb



require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("css/application.scss")
require('typeface-inter')
require("jquery")
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
//require("packs/notifications")

import 'trix/dist/trix.css';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

const application = Application.start()
const context = require.context("controllers", true, /.js$/);
require.context('images', true)
application.load(definitionsFromContext(context))

import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import 'alpinejs'
function addEvent(element, evnt, funct) {
    if (element !== null) {
        if (element.attachEvent)
            return element.attachEvent('on' + evnt, funct);
        else
            return element.addEventListener(evnt, funct, false);
    }
  }

var Trix = require('trix');

/* what the newly created button does */
Trix.config.textAttributes.hr = { tagName: 'hr', inheritable: true };
Trix.config.textAttributes.red = {
	style: { color: 'red' },
    tagname: "span",
	parser: function (element) {
		return element.style.color === 'red';
	},
	inheritable: true,
};
Trix.config.textAttributes.blue = {
	style: { color: 'blue' },
    tagname: "span",
	parser: function (element) {
		return element.style.color === 'blue';
	},
	inheritable: true,
};
Trix.config.textAttributes.green = {
	style: { color: 'green' },
    tagname: "span",
	parser: function (element) {
		return element.style.color === 'green';
	},
	inheritable: true,
};

/* insert the button visual in the default toolbar */
addEventListener('trix-initialize', function (event) {
	var buttonHTML = '<button type="button" class="trix-button" data-trix-action="x-horizontal-rule">-</button>';
    // buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="red">RED</button>';
    // buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="green">GREEN</button>';
    // buttonHTML += '<button type="button" class="trix-button" data-trix-attribute="blue">BLUE</button>';

	event.target.toolbarElement.querySelector('.trix-button-group').insertAdjacentHTML('beforeend', buttonHTML);
});

addEventListener('trix-action-invoke', (event) => {
	if (event.actionName == 'x-horizontal-rule') {
		const { editor } = event.target;
		const attachment = new Trix.Attachment({ content: '<hr>', contentType: 'application/vnd.rubyonrails.horizontal-rule.html' });
		editor.insertAttachment(attachment);
	}
});


window.addEventListener('load', function () {
    addEvent(
        document.getElementById('copypaste_public_dns_key'),
        'click',
        function () {
            copypaste("public_dns_key");
        }
    );
    addEvent(
        document.getElementById('copypaste_private_key'),
        'click',
        function () {
            copypaste("private_key");
        }
    );
});

let copypaste = function (textareaid) {
    const textarea = document.getElementById(textareaid);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    window.showSuccess ("Copied");

};


document.addEventListener("turbolinks:load", () => {
    flatpickr(".flatpickr", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
    });
});

document.addEventListener("turbolinks:render", () => {
    addEvent(
        document.getElementById('copypaste_public_dns_key'),
        'click',
        function () {
            copypaste("public_dns_key");
        }
    );
    addEvent(
        document.getElementById('copypaste_private_key'),
        'click',
        function () {
            console.log("Copied")
            copypaste("private_key");
        }
    );
})
